.ui.table.celled tr.not_celled {
  th, td {
    border-left-color: transparent;
  }
}

.ui.table.transparent {
  background: transparent;
}

.ui.compact.table th {
    padding: 0.5rem 0.7rem;
}

/* a custom color for Semantic 'negative' cell background color. */
.ui.table tr.negative, .ui.table td.negative {
    background: @red100 !important;
}

/* Corrects visual issue when nesting two celled tables */
.ui.table tr:first-child .ui.table tr:not(:first-child) td {
  border-top: @rowBorder;
}

.ui.table.grouped-table {
  border: none;
  background: none;

  .spacer-row {
    background: none;

    * {
      background: none;
      border-bottom: none;
    }

    th {
      height: 15px;
      padding: 0;
    }

    &:first-child { height: 0; }
  }

  thead:not(.spacer-row) > tr:first-child {
    th { border-top: @border; }

    th:first-child {
      border-left: @border;
      border-radius: @borderRadius 0 0 0;
    }

    th:last-child {
      border-right: @border;
      border-radius: 0 @borderRadius 0 0;
    }
  }

  tbody:not(.spacer-row) {
    td:first-child { border-left: @border; }

    td:last-child { border-right: @border; }

    tr:last-child {
      td { border-bottom: @border; }
      td:first-child { border-radius: 0 0 0 @borderRadius; }
      td:last-child { border-radius: 0 0 @borderRadius 0; }
    }
  }
}
