.ui.message.lp-info-cue {
  border: none;
  border-bottom-left-radius: 2px;
  border-left: 12px solid @borderColor;
  border-top-left-radius: 2px;
  box-shadow: none;
  padding-left: 12px;

  .header {
    display: inline-block;
    padding-right: 10px;
  }

  &.icon {
    > .icon:not(.dismiss) {
      font-size: 2em;
      margin-right: 0.3em;
    }

    > .icon.dismiss {
      font-size: 1.7em;
      margin-right: -0.25em;
    }
  }

  a {
    font-weight: bold;
  }


  &.green {
    border-left-color: @greenBorderColor;

    a {
      color: @greenTextColor;
    }
  }
}
