/*******************************
         Theme Overrides
*******************************/
.ui.menu:not(.vertical) .item .button:not(.dropdown) {
  padding-bottom: 0;
  padding-top: 0;
}

.ui.secondary.pointing.menu .item {
  border-width: 4px;
  margin-left: @relativeLarge;
  margin-right: @relativeLarge;
}

.ui.secondary.pointing.menu .active.item {
  border-width: 4px;
}


/*-------------------
  Inverted dropdowns
--------------------*/
.ui.menu .ui.inverted.inverted.dropdown.item .menu {
  background: @invertedDropdownBackground;
  box-shadow: @invertedDropdownMenuBoxShadow;
}

.ui.menu .ui.inverted.dropdown .menu > .item {
  color: @invertedDropdownItemColor !important;
}

.ui.menu .ui.inverted.dropdown .menu > .active.item {
  background: @invertedDropdownActiveItemBackground !important;
  color: @invertedDropdownActiveItemColor !important;
}

.ui.menu .ui.inverted.dropdown .menu > .item:hover {
  background: @invertedDropdownHoveredItemBackground !important;
  color: @invertedDropdownHoveredItemColor !important;
}

.ui.menu .ui.inverted.dropdown .menu > .selected.item {
  background: @invertedDropdownSelectedItemBackground !important;
  color: @invertedDropdownSelectedItemColor !important;
}

/* Vertical */
.ui.vertical.menu .inverted.dropdown.item .menu {
  box-shadow: @invertedDropdownMenuBoxShadow;
}

.ui.vertical.menu.icon-left .item .icon {
  float: none;
  margin: 0em @relative5px 0em 0em;
}

