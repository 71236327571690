/*******************************
         Theme Overrides
*******************************/

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before,
.ui.checkbox input:checked:focus ~ label:before {
  background: @slate700;
}

.ui.checkbox label:hover::before {
  border: 1px solid @blue200
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after,
.ui.checkbox input:checked:focus ~ label:after {
 color: @lpBrandWhite;
}

ui.checkbox input:focus {
  border: 1px solid @lpFocusColor
}
