/*******************************
         Theme Overrides
*******************************/
.ui.menu:not(.vertical) .item .dropdown {
  display: flex;
  align-items: center;

  height: @controlRegularSize;
  min-height: 1em;
}

/* Dropdown Carets */
@font-face {
  font-family: 'Dropdown';
  src:
    url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMggjB5AAAAC8AAAAYGNtYXAPfuIIAAABHAAAAExnYXNwAAAAEAAAAWgAAAAIZ2x5Zjo82LgAAAFwAAABVGhlYWQAQ88bAAACxAAAADZoaGVhAwcB6QAAAvwAAAAkaG10eAS4ABIAAAMgAAAAIGxvY2EBNgDeAAADQAAAABJtYXhwAAoAFgAAA1QAAAAgbmFtZVcZpu4AAAN0AAABRXBvc3QAAwAAAAAEvAAAACAAAwIAAZAABQAAAUwBZgAAAEcBTAFmAAAA9QAZAIQAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADw2gHg/+D/4AHgACAAAAABAAAAAAAAAAAAAAAgAAAAAAACAAAAAwAAABQAAwABAAAAFAAEADgAAAAKAAgAAgACAAEAIPDa//3//wAAAAAAIPDX//3//wAB/+MPLQADAAEAAAAAAAAAAAAAAAEAAf//AA8AAQAAAAAAAAAAAAIAADc5AQAAAAABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQAAAIABJQElABMAABM0NzY3BTYXFhUUDwEGJwYvASY1AAUGBwEACAUGBoAFCAcGgAUBEgcGBQEBAQcECQYHfwYBAQZ/BwYAAQAAAG4BJQESABMAADc0PwE2MzIfARYVFAcGIyEiJyY1AAWABgcIBYAGBgUI/wAHBgWABwaABQWABgcHBgUFBgcAAAABABIASQC3AW4AEwAANzQ/ATYXNhcWHQEUBwYnBi8BJjUSBoAFCAcFBgYFBwgFgAbbBwZ/BwEBBwQJ/wgEBwEBB38GBgAAAAABAAAASQClAW4AEwAANxE0NzYzMh8BFhUUDwEGIyInJjUABQYHCAWABgaABQgHBgVbAQAIBQYGgAUIBwWABgYFBwAAAAEAAAABAADZuaKOXw889QALAgAAAAAA0ABHWAAAAADQAEdYAAAAAAElAW4AAAAIAAIAAAAAAAAAAQAAAeD/4AAAAgAAAAAAASUAAQAAAAAAAAAAAAAAAAAAAAgAAAAAAAAAAAAAAAABAAAAASUAAAElAAAAtwASALcAAAAAAAAACgAUAB4AQgBkAIgAqgAAAAEAAAAIABQAAQAAAAAAAgAAAAAAAAAAAAAAAAAAAAAAAAAOAK4AAQAAAAAAAQAOAAAAAQAAAAAAAgAOAEcAAQAAAAAAAwAOACQAAQAAAAAABAAOAFUAAQAAAAAABQAWAA4AAQAAAAAABgAHADIAAQAAAAAACgA0AGMAAwABBAkAAQAOAAAAAwABBAkAAgAOAEcAAwABBAkAAwAOACQAAwABBAkABAAOAFUAAwABBAkABQAWAA4AAwABBAkABgAOADkAAwABBAkACgA0AGMAaQBjAG8AbQBvAG8AbgBWAGUAcgBzAGkAbwBuACAAMQAuADAAaQBjAG8AbQBvAG8Abmljb21vb24AaQBjAG8AbQBvAG8AbgBSAGUAZwB1AGwAYQByAGkAYwBvAG0AbwBvAG4ARgBvAG4AdAAgAGcAZQBuAGUAcgBhAHQAZQBkACAAYgB5ACAASQBjAG8ATQBvAG8AbgAuAAAAAAMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=) format('truetype'),
    url(data:application/font-woff;charset=utf-8;base64,d09GRk9UVE8AAAVwAAoAAAAABSgAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABDRkYgAAAA9AAAAdkAAAHZLDXE/09TLzIAAALQAAAAYAAAAGAIIweQY21hcAAAAzAAAABMAAAATA9+4ghnYXNwAAADfAAAAAgAAAAIAAAAEGhlYWQAAAOEAAAANgAAADYAQ88baGhlYQAAA7wAAAAkAAAAJAMHAelobXR4AAAD4AAAACAAAAAgBLgAEm1heHAAAAQAAAAABgAAAAYACFAAbmFtZQAABAgAAAFFAAABRVcZpu5wb3N0AAAFUAAAACAAAAAgAAMAAAEABAQAAQEBCGljb21vb24AAQIAAQA6+BwC+BsD+BgEHgoAGVP/i4seCgAZU/+LiwwHi2v4lPh0BR0AAACIDx0AAACNER0AAAAJHQAAAdASAAkBAQgPERMWGyAlKmljb21vb25pY29tb29udTB1MXUyMHVGMEQ3dUYwRDh1RjBEOXVGMERBAAACAYkABgAIAgABAAQABwAKAA0AVgCfAOgBL/yUDvyUDvyUDvuUDvtvi/emFYuQjZCOjo+Pj42Qiwj3lIsFkIuQiY6Hj4iNhouGi4aJh4eHCPsU+xQFiIiGiYaLhouHjYeOCPsU9xQFiI+Jj4uQCA77b4v3FBWLkI2Pjo8I9xT3FAWPjo+NkIuQi5CJjogI9xT7FAWPh42Hi4aLhomHh4eIiIaJhosI+5SLBYaLh42HjoiPiY+LkAgO+92d928Vi5CNkI+OCPcU9xQFjo+QjZCLkIuPiY6Hj4iNhouGCIv7lAWLhomHh4iIh4eJhouGi4aNiI8I+xT3FAWHjomPi5AIDvvdi+YVi/eUBYuQjZCOjo+Pj42Qi5CLkImOhwj3FPsUBY+IjYaLhouGiYeHiAj7FPsUBYiHhomGi4aLh42Hj4iOiY+LkAgO+JQU+JQViwwKAAAAAAMCAAGQAAUAAAFMAWYAAABHAUwBZgAAAPUAGQCEAAAAAAAAAAAAAAAAAAAAARAAAAAAAAAAAAAAAAAAAAAAQAAA8NoB4P/g/+AB4AAgAAAAAQAAAAAAAAAAAAAAIAAAAAAAAgAAAAMAAAAUAAMAAQAAABQABAA4AAAACgAIAAIAAgABACDw2v/9//8AAAAAACDw1//9//8AAf/jDy0AAwABAAAAAAAAAAAAAAABAAH//wAPAAEAAAABAAA5emozXw889QALAgAAAAAA0ABHWAAAAADQAEdYAAAAAAElAW4AAAAIAAIAAAAAAAAAAQAAAeD/4AAAAgAAAAAAASUAAQAAAAAAAAAAAAAAAAAAAAgAAAAAAAAAAAAAAAABAAAAASUAAAElAAAAtwASALcAAAAAUAAACAAAAAAADgCuAAEAAAAAAAEADgAAAAEAAAAAAAIADgBHAAEAAAAAAAMADgAkAAEAAAAAAAQADgBVAAEAAAAAAAUAFgAOAAEAAAAAAAYABwAyAAEAAAAAAAoANABjAAMAAQQJAAEADgAAAAMAAQQJAAIADgBHAAMAAQQJAAMADgAkAAMAAQQJAAQADgBVAAMAAQQJAAUAFgAOAAMAAQQJAAYADgA5AAMAAQQJAAoANABjAGkAYwBvAG0AbwBvAG4AVgBlAHIAcwBpAG8AbgAgADEALgAwAGkAYwBvAG0AbwBvAG5pY29tb29uAGkAYwBvAG0AbwBvAG4AUgBlAGcAdQBsAGEAcgBpAGMAbwBtAG8AbwBuAEYAbwBuAHQAIABnAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4ALgAAAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA) format('woff')
  ;
  font-weight: normal;
  font-style: normal;
}

.ui.dropdown > .dropdown.icon {
  font-family: 'Dropdown';
  line-height: 1;
  height: 1em;
  width: 1.23em;
  backface-visibility: hidden;
  font-weight: normal;
  font-style: normal;
  text-align: center;
}

.ui.dropdown > .dropdown.icon {
  width: auto;
}
.ui.dropdown > .dropdown.icon:before {
  content: '\f0d7';
}
.ui.dropdown > .dropdown.clear.icon:before {
  content: "\f00d";
}

/* Sub Menu */
.ui.dropdown .menu .item .dropdown.icon:before {
  content: '\f0da'/*rtl:'\f0d9'*/;
}

.ui.dropdown .item .left.dropdown.icon:before,
.ui.dropdown .left.menu .item .dropdown.icon:before {
  content: "\f0d9"/*rtl:"\f0da"*/;
}

/* Vertical Menu Dropdown */
.ui.vertical.menu .dropdown.item > .dropdown.icon:before {
  content: "\f0da"/*rtl:"\f0d9"*/;
}

/* Icons for Reference
.dropdown.down.icon {
  content: "\f0d7";
}
.dropdown.up.icon {
  content: "\f0d8";
}
.dropdown.left.icon {
  content: "\f0d9";
}
.dropdown.icon.icon {
  content: "\f0da";
}
*/

.ui.dropdown:not(.button) > .default.text,
.ui.default.dropdown:not(.button) > .text {
  color: @grey;
}

/*--------------
    Selection
---------------*/

.ui.selection.dropdown {
  &:hover {
      border: 1px solid @blue200
  }

  &:focus {
      border: 1px solid @lpFocusColor
  }
}

.ui.selection.dropdown.gray {
  background: #DDD;
}

.ui.selection.dropdown {
    max-width: 28rem;
}

.ui.selection.dropdown .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: .3rem;
}

.ui.selection.dropdown .menu > .item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* This gets any dropdown that is a selection(which acts like a button) */
/* to match the size of the button now and updates the icons as well so it all matches */
.ui.selection.dropdown {
  padding: 0.3rem;
  min-height: 2.3rem;

  dropdown.icon {
    padding: .3rem;
  }
}

/*--------------
    Inverted
---------------*/

/* General rules and basic dropdowns */
.ui.inverted.dropdown .menu {
  background: red; //@invertedMenuBackground;
  box-shadow: @invertedMenuBoxShadow;
  border: @invertedMenuBorder;
}

.ui.inverted.dropdown .menu > .item {
  color: @invertedMenuColor;
}

.ui.inverted.dropdown .menu .active.item {
  background: @invertedActiveItemBackground;
  color: @invertedActiveItemColor;
  box-shadow: @invertedActiveItemBoxShadow;
}

.ui.inverted.dropdown .menu > .item:hover {
  background: @invertedHoveredItemBackground;
  color: @invertedHoveredItemColor;
}

.ui.inverted.dropdown.selected,
.ui.inverted.dropdown .menu .selected.item {
  background: @invertedSelectedBackground;
  color: @invertedSelectedColor;
}

.ui.inverted.dropdown .menu > .header {
  color: @invertedMenuHeaderColor;
}

.ui.inverted.dropdown > .text > .description,
.ui.inverted.dropdown .menu > .item > .description {
  color: @invertedItemDescriptionColor;
}

.ui.inverted.dropdown .menu > .divider {
  border-top: @invertedMenuDividerBorder;
}

.ui.inverted.dropdown .scrolling.menu {
  border: none;
  border-top: @invertedMenuBorder;
}

/* Selection */
.ui.inverted.selection.dropdown {
  border: @invertedSelectionBorder;
  background: @invertedSelectionBackground;
  color: @invertedSelectionTextColor;
}

.ui.inverted.selection.dropdown:hover {
  border-color: @invertedSelectionHoverBorderColor;
  box-shadow: @invertedSelectionHoverBoxShadow;
}

.ui.inverted.selection.dropdown input {
  color: @invertedSelectionInputTextColor;
}

.ui.inverted.dropdown:not(.button) > .default.text,
.ui.inverted.default.dropdown:not(.button) > .text {
  color: @invertedDefaultTextColor;
}
.ui.inverted.dropdown:not(.button) > input:focus ~ .default.text,
.ui.inverted.default.dropdown:not(.button) > input:focus ~ .text {
  color: @invertedDefaultTextFocusColor;
}

.ui.inverted.selection.visible.dropdown > .text:not(.default) {
  color: @invertedSelectionVisibleTextColor;
}

.ui.inverted.active.search.dropdown input.search:focus + .text .icon,
.ui.inverted.active.search.dropdown input.search:focus + .text .flag {
  opacity: @invertedSelectionTextUnderlayIconOpacity;
}
.ui.inverted.active.search.dropdown input.search:focus + .text {
  color: @invertedSelectionTextUnderlayColor !important;
}

.ui.inverted.selection.active.dropdown .menu,
.ui.inverted.selection.active.dropdown:hover {
  border-color: @invertedSelectionVisibleBorderColor;
}

.ui.inverted.selection.dropdown .menu > .item {
  border-top: @invertedSelectionItemDivider;
}

.ui.dropdown .menu > .message:not(.ui) {
  color: @invertedMessageColor;
}

/* Fixing the border */
.ui.inverted.dropdown .menu > .item:first-child {
  border-top-width: 0;
}

/* Labels */
.ui.inverted.multiple.dropdown > .label {
  background-color: @invertedLabelBackgroundColor;
  background-image: @invertedLabelBackgroundImage;
  color: @invertedLabelColor;
  box-shadow: @invertedLabelBoxShadow;
}

.ui.inverted.multiple.dropdown > .label:hover {
  background-color: @invertedLabelHoverBackgroundColor;
  border-color: @invertedLabelHoverBackgroundColor;

  background-image: @invertedLabelHoverBackgroundImage;
  color: @invertedLabelHoverTextColor;
}

.ui.inverted.multiple.dropdown > .label > .close.icon,
.ui.inverted.multiple.dropdown > .label > .delete.icon {
  opacity: @invertedLabelIconOpacity;
}

.ui.inverted.multiple.dropdown > .label > .close.icon:hover,
.ui.inverted.multiple.dropdown > .label > .delete.icon:hover {
  opacity: @invertedLabelIconHoverOpacity;
}

/* Selection for form elements */
.ui.inverted.dropdown textarea::-webkit-selection,
.ui.inverted.dropdown input::-webkit-selection {
  background-color: @invertedInputHighlightBackground;
  color: @invertedInputHighlightColor;
}
.ui.inverted.dropdown textarea::-moz-selection,
.ui.inverted.dropdown input::-moz-selection {
  background-color: @invertedInputHighlightBackground;
  color: @invertedInputHighlightColor;
}
.ui.inverted.dropdown textarea::selection,
.ui.inverted.dropdown input::selection {
  background-color: @invertedInputHighlightBackground;
  color: @invertedInputHighlightColor;
}

/* Scrollbars */
.ui.inverted.dropdown .menu::-webkit-scrollbar-track {
  background: @trackInvertedBackground;
}
.ui.inverted.dropdown .menu::-webkit-scrollbar-thumb {
  background: @thumbInvertedBackground;
}
.ui.inverted.dropdown .menu::-webkit-scrollbar-thumb:window-inactive {
  background: @thumbInvertedInactiveBackground;
}
.ui.inverted.dropdown .menu::-webkit-scrollbar-thumb:hover {
  background: @thumbInvertedHoverBackground;
}


/*******************************************************************************
  Lp Plain - plain blue, and borderless. Like the LPX panel dropdwons.
*******************************************************************************/

.ui.lp-plain.dropdown {
  background: transparent;
  border-color: transparent;
  color: @lpPlainLinkColor;
}

.ui.lp-plain.dropdown:hover {
  border-color: transparent;

  > .text {
    text-decoration: underline;
  }
}

/*******************************************************************************
  icon_left moves icon to the left
*******************************************************************************/

.ui.icon_left.dropdown {
  display: flex;

  .text { order: 2; }
  .icon {
    order: 1;
    margin-right: .6em;
  }
  .menu { order: 3; }
}

/*******************************************************************************
  "reduce" the dropdown size
*******************************************************************************/

.ui.selection.dropdown.dropdown-small,
.ui.search.selection.dropdown.dropdown-small {
  padding: 8px 0 0 5px;
}
