.ui.form {
    textarea {
        font-family: @inputFont;

        &:hover {
            border: 1px solid @blue200
        }

        &:focus {
            border: 1px solid @lpFocusColor
        }
    }

    input[type="text"] {
         &:hover {
            border: 1px solid @blue200
        }

        &:focus {
            border: 1px solid @lpFocusColor
        }
    }
}

.ui.form .field .ui.input input {
    padding-left: .5rem
}
