/*******************************
         Theme Overrides
*******************************/

.ui.modal > .close {
  cursor: pointer;
  position: static;
  float: right;
  font-size: 1.25em;
  color: #3c3c3c;
  padding: 5px;
  padding-top: 10px;
}

.ui.modal > .header {
  border-bottom: none;
  padding-bottom: 0;

  &:not(.ui) {
    font-weight: @weightSemiBold;
  }
}


.ui.modal > .actions {
  border-top: none;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-top: 0;
  background-color: @lpBrandWhite;
}
