/*******************************
         Theme Overrides
*******************************/
.ui.button:not(.dropdown) {
    height: @controlRegularSize;
    box-sizing: border-box;
}

.ui.button:focus, a.ui.button:focus{
    outline: 2px solid @blueFocus;
    outline-offset: -2px;
}