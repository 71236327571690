.ui.input {
    height: @controlRegularSize;
    box-sizing: border-box;

    input {
      overflow: hidden;

        &:hover {
            border: 1px solid @blue200
        }

        &:focus {
            border: 1px solid @lpFocusColor
        }
    }
}

